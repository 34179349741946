import '@/shared/setEnv'
import VueGtag from 'vue-gtag'
import { loadFonts } from './webfontloader'
import { sentryInit } from '@/plugins/sentry'
import pinia from '../store'
import vuetify from './vuetify'
import router from '../router'
import i18n from './i18n'

export function registerPlugins(app) {
  app.config.globalProperties.isDev = window.isDev
  loadFonts()
  sentryInit({ app, router })
  app
    .use(pinia)
    .use(vuetify)
    .use(router)
    .use(i18n)
    .use(VueGtag, {
      appName: 'ISL Project Inscription',
      pageTrackerScreenviewEnabled: true,
      config: {
        id: import.meta.env.VITE_GA_MEASUREMENT_ID,
      },
      router,
    })
}
