import { collection, getDocs, query, where } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { db } from '@/plugins/firebase'

export const useCouponStore = defineStore('couponStore', {
  state: () => ({
    coupon: null,
  }),
  actions: {
    async getCoupon({ code, organizationId }) {
      const q = query(collection(db, 'coupons'), where('code', '==', code), where('organizationId', '==', organizationId))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(doc => { this.coupon = doc.data() })
    },
    deleteCoupon() {
      this.coupon = null
    },
  },
})
