import http from 'axios'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCk8wSWqPgPUOEGsjA9ze6brgojF4FvK0I',
  authDomain: 'owqlo-master-production-id.firebaseapp.com',
  databaseURL: 'https://owqlo-master-production-id.firebaseio.com',
  projectId: 'owqlo-master-production-id',
  storageBucket: 'owqlo-master-production-id.appspot.com',
  messagingSenderId: '737501983455',
  appId: '1:737501983455:web:358966b523fb8405252f6a',
  measurementId: 'G-8M8F6GCE4G',
}

const callBackend = async (endpoint, data) => {
  const backendUrl = `https://${window.isDev ? 'staging-dot-owqlo-master-production-id' : 'owqlo-master-production-id'}.ew.r.appspot.com`
  // const backendUrl = 'http://localhost:8081'
  try {
    const res = await http.post(`${backendUrl}/${endpoint}`, data)
    return res.data
  } catch (error) {
    return error.response.data
  }
}

const uploadFileAndGetUrl = async (storagePath, file) => { // For images
  if (!(file instanceof File)) return file // this happens when the file has been readed from the database, so it is just an URL, or when the file has been deleted an is null
  const storage = getStorage()
  const storageRef = ref(storage, `${storagePath}/${file.name}`)

  const uploadTask = uploadBytesResumable(storageRef, file)

  await uploadTask

  // Get the download URL
  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
  return downloadURL
}

const uploadFileAndGetObj = async (storagePath, file) => { // For non-images
  if (!(file instanceof File)) return file // this happens when the file has been readed from the database, so it is a simple object
  const storage = getStorage()
  const storageRef = ref(storage, `${storagePath}/${file.name}`)

  const uploadTask = uploadBytesResumable(storageRef, file)

  await uploadTask

  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)

  return {
    publicUrl: downloadURL,
    name: file.name,
    type: file.type,
    sizeInBytes: file.size,
    storagePath: `${storagePath}/`,
  }
}

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const analytics = getAnalytics(firebaseApp)

export { firebaseConfig, firebaseApp, db, analytics, callBackend, uploadFileAndGetUrl, uploadFileAndGetObj }
