/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'
import * as labsComponents from 'vuetify/labs/components'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    ...labsComponents,
  },
  theme: {
    themes: {
      light: {
        colors: {
          'primary': '#154284',
          'secondary': '#CF122D',
          'disabled': '#9E9E9E',
          'background': '#181733',
          'on-background': '#222222',
          'on-background-variant': '#FFF',
        },
      },
    },
  },
})
