import { Timestamp, doc, getDoc, setDoc, query, collection, onSnapshot, where } from 'firebase/firestore'
import { defineStore } from 'pinia'
import pinia from '@/store'
import { callBackend, db } from '@/plugins/firebase'
import { useUserStore } from '@/store/userStore.js'
import { useVendorStore } from '@/store/vendorStore.js'
import { useDynamicFormStore } from '@/store/dynamicFormStore'
import currenciesSymbols from '@/shared/enums/currenciesSymbols.js'
import rolesEnum from '@/shared/enums/rolesEnum'

const vendorStore = useVendorStore(pinia)

export const useProjectStore = defineStore('projectStore', {
  state: () => ({
    project: null,
    form: {},
    paymentMethod: null,
    waiver: [],
    staticContentWaiver: [],
    players: [],
    terms: [],
  }),
  getters: {
    price: state => state.project?.pricing?.priceOptions[0]?.instant?.amount ?? null,
    priceOptionId: state => state.project?.pricing?.priceOptions[0]?.id,
    timezone: state => state.project?.timezone,
    formatPrice: state => price => state.price ? `${price.toFixed(2)} ${currenciesSymbols[vendorStore.currency]}` : null,
    isProjectFree: state => !state.project?.features?.price,
  },
  actions: {
    setForm({ form }) { this.form = form },
    setPaymentMethod({ paymentMethod }) { this.paymentMethod = paymentMethod },
    async bindProject({ organizationId, projectId }) {
      const docRef = doc(db, 'properties', organizationId, 'projects', projectId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.project = docSnap.data()
      } else {
        console.log('projectStore.js -> bindProject: No such document!')
      }
    },
    async bindWaiver({ organizationId, projectId }) {
      const colRef = query(collection(db, 'properties', organizationId, 'projects', projectId, 'waiver'))
      onSnapshot(colRef, snapshot => {
        const data = []
        snapshot.forEach(res => { data.push(res.data()) })
        this.waiver = data
      })
    },
    async bindTerms({ organizationId, projectId }) {
      const colRef = query(collection(db, 'properties', organizationId, 'projects', projectId, 'terms'))
      onSnapshot(colRef, snapshot => {
        const data = []
        snapshot.forEach(res => { data.push(res.data()) })
        this.terms = data
      })
    },
    async bindPlayers({ organizationId, projectId }) {
      const colRef = query(collection(db, 'users'), where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PLAYER))
      onSnapshot(colRef, snapshot => {
        const data = []
        snapshot.forEach(res => { data.push(res.data()) })
        this.players = data
      })
    },
    async bindStaticContentWaiver() {
      const colRef = query(collection(db, 'staticContent', 'projectWaiver', 'waiver'))
      onSnapshot(colRef, snapshot => {
        const data = []
        snapshot.forEach(res => { data.push(res.data()) })
        this.staticContentWaiver = data
      })
    },
    async createEnrollment({ organizationId, projectId, data }) {
      const enrollmentsRef = doc(db, 'properties', organizationId, 'projects', projectId, 'enrollments', data.id)
      await setDoc(enrollmentsRef, { ...data })
    },
    async createOrder({ formData, organizationId, projectId }) {
      const userStore = useUserStore()
      const dynamicFormStore = useDynamicFormStore()
      let parentUser = null
      let parentInfoForm = null

      // 0. Verify that the parent's information is requested on the form and parse dynamic form if exists
      if (dynamicFormStore?.dataUser?.parentProfileFields) {
        const { parentDynamicForm, email, ...parentData } = formData.parentForm
        parentInfoForm = parentDynamicForm?.length ? this.parseDynamicForm({ dynamicForm: parentDynamicForm }) : null

        // 1. Back Create parent user if no exixts. If exists update parent user.
        parentUser = await userStore.readByEmail(email)

        if (!parentUser) {
          parentUser = { id: null, ...parentData }
          const response = await callBackend('users/create-programatically', {
            data: parentUser,
            email,
            phone: parentData?.phone,
          })
          parentUser.id = response.id
        } else {
          await userStore.update({ id: parentUser.id, data: parentData })
          // change data of dynamicForm in all players
          if (parentInfoForm) {
            await Promise.all(this.players.filter(rP => parentUser.linkedUsers?.children.includes(rP.id)).map(async p => {
              await userStore.updateProjectMembershipParentFields({
                id: p.id,
                role: 'subscriber',
                entityType: 'project',
                entityId: projectId,
                data: { form: { ...parentInfoForm } },
              })
            }))
          }
        }
        userStore.setParentId({ parentId: parentUser.id })
      }

      // 2. Save parent address, now saved address in users/create-programatically
      // await userStore.createAddress({ userId: parentUser.id, data: formData.addressForm })

      // 3. Back Create player user.
      const paymentItems = await Promise.all(formData.playerForm.map(async player => {
        const { playerDynamicForm, email, cart, ...playerData } = player
        const playerUser = { id: null, ...playerData }
        const response = await callBackend('users/create-programatically', {
          data: playerUser,
          ...(email && { email }),
        })
        if (response.error) { throw new Error(response.error) }
        playerUser.id = response.id
        if (!userStore.playerId) userStore.setPlayerId({ playerId: playerUser.id })

        // 4. Save player address
        if (formData.addressForm) await userStore.createAddress({ userId: playerUser.id, data: formData.addressForm })

        // 5. Link users if the parent's data is requested
        if (dynamicFormStore?.dataUser?.parentProfileFields) {
          await callBackend('users/link', {
            user1: { id: parentUser?.id, relationshipRole: 'parent' },
            user2: { id: playerUser.id, relationshipRole: 'child' },
          })
        }

        // 6. Create enrollment
        const playerInfoForm = playerDynamicForm?.length ? this.parseDynamicForm({ dynamicForm: playerDynamicForm }) : null
        const enrollment = {
          id: playerUser.id,
          userId: playerUser.id,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          status: 'pendingApproval',
          role: 'player',
          enrolledBy: parentUser?.id ?? playerUser?.id,
          ...((parentInfoForm || playerInfoForm)
          && {
            info: {
              form: {
                ...(parentInfoForm && { ...parentInfoForm }),
                ...(playerInfoForm && { ...playerInfoForm }),
              },
            },
          }),
          ...(this.project.features?.waiver && {
            waiverSignatureBase64: formData.waiverForm.signature.replace('data:image/png;base64,', ''),
            waiverName: formData.waiverForm.name,
          }),
        }
        await this.createEnrollment({ organizationId, projectId, data: enrollment })
        if (this.isProjectFree) { await callBackend('projects/users/approve-enrollment', { organizationId, projectId, enrollmentId: enrollment.id }) }

        // 7. Create payment items
        const cartItems = cart.map(item => ({
          id: item.id,
          type: item.type,
          name: item.name,
          image: item.image,
          amount: item.amount,
          currency: vendorStore.currency,
          units: 1,
          organizationId,
          projectId,
          recipientUserId: playerUser.id,
          enrollmentId: enrollment.id,
          ...((item.type === 'project' && this.priceOptionId) && { priceOptionId: this.priceOptionId }),
        }))
        return cartItems
      }))
      if (this.isProjectFree) return true
      const paymentUserId = parentUser?.id ?? userStore.playerId
      // 8. Create default payment method
      const defaultPaymentMethodId = await userStore.createPaymentMethod({ userId: paymentUserId, data: this.paymentMethod })
      await userStore.update({ id: paymentUserId, data: { defaultPaymentMethodId } })
      // 9. Create user cart
      const allItems = paymentItems.flatMap(items => items)
      await userStore.createUserCart({ userId: paymentUserId, paymentItems: allItems })
      // 10. Create order
      const order = await callBackend('stripe/create-order', {
        userId: paymentUserId,
      })
      return order
    },
    parseDynamicForm({ dynamicForm }) {
      return dynamicForm.reduce((result, item) => {
        result[item.id] = item.value
        return result
      }, {})
    },
  },
})
