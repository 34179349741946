import * as Sentry from '@sentry/vue'

export function sentryInit({ app, router }) {
  if (!window.isDev) {
    Sentry.init({
      app,
      dsn: 'https://ce6ebd5e6cc800b4c54583795c768ced@o290190.ingest.sentry.io/4506144639614976',
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['https://enrollment.owqlo.com'],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }
}
