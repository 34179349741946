import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { db } from '@/plugins/firebase'
import { useProjectStore } from '@/store/projectStore'

export const useVendorStore = defineStore('vendorStore', {
  state: () => ({
    vendor: null,
  }),
  getters: {
    currency: state => state.vendor?.stripe?.currency?.toLowerCase() ?? 'usd',
  },
  actions: {
    async fetchVendorFromQuery(organizationId, projectId) {
      const q = query(collection(db, 'vendors'), where('organizationId', '==', organizationId), where('ownerProjectId', '==', projectId))
      const querySnapshot = await getDocs(q)
      return querySnapshot.docs.length ? querySnapshot.docs[0].data() : null
    },
    async fetchVendorFromProject(vendorId) {
      if (!vendorId) return null
      const docRef = doc(db, 'vendors', vendorId)
      const docSnap = await getDoc(docRef)
      return docSnap.exists() ? docSnap.data() : null
    },
    async bindVendor({ organizationId, projectId }) {
      const projectStore = useProjectStore()
      try {
        const vendor = await this.fetchVendorFromQuery(organizationId, projectId) || await this.fetchVendorFromProject(projectStore?.project?.vendorId)
        if (vendor) this.vendor = vendor
        else console.log('vendorStore.js -> bindVendor: No such vendor doc!')
      } catch (error) {
        console.log('vendorStore.js -> bindVendor:', error)
      }
    },
  },
})
