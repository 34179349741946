import { collection, getDocs, query, where } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { db } from '@/plugins/firebase'

export const useProductStore = defineStore('productStore', {
  state: () => ({
    products: [],
  }),
  actions: {
    async bindProducts({ organizationId, projectId }) {
      const compProjectIdsForEnrollment = `organization-${organizationId}--project-${projectId}`
      const q = query(
        collection(db, 'products'),
        where('organizationId', '==', organizationId),
        where('compProjectIdsForEnrollment', 'array-contains', compProjectIdsForEnrollment),
        where('isActive', '==', true),
        where('isPublished', '==', true),
      )
      const querySnapshot = await getDocs(q)
      this.products = querySnapshot.docs.map(doc => doc.data())
    },
  },
})
